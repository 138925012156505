"use client";
import "@wojo/theme/reset.scss";
import {
    getThemeClassName,
    initLogRocket,
    logRocketOnError,
} from "@wojo/web-components";
import localFont from "next/font/local";
import { CSSProperties, useEffect } from "react";
import ErrorPage from "./(tours)/error";

const efCircular = localFont({
    src: [
        {
            path: "./fonts/EFCircularSub-Book.woff2",
            weight: "300",
            style: "normal",
        },
        {
            path: "./fonts/EFCircularSub-BookItalic.woff2",
            weight: "300",
            style: "italic",
        },
        {
            path: "./fonts/EFCircularSub-Light.woff2",
            weight: "200",
            style: "normal",
        },
        {
            path: "./fonts/EFCircularSub-LightItalic.woff2",
            weight: "200",
            style: "italic",
        },
        {
            path: "./fonts/EFCircularSub-Medium.woff2",
            weight: "500",
            style: "normal",
        },
        {
            path: "./fonts/EFCircularSub-MediumItalic.woff2",
            weight: "500",
            style: "italic",
        },
        {
            path: "./fonts/EFCircularSub-Bold.woff2",
            weight: "700",
            style: "normal",
        },
        {
            path: "./fonts/EFCircularSub-BoldItalic.woff2",
            weight: "700",
            style: "italic",
        },
    ],
});

export default function GlobalError({
    error,
}: {
    error: Error & { digest?: string };
}) {
    useEffect(() => {
        initLogRocket();
        logRocketOnError(error.message || "");
    }, [error]);

    return (
        <html
            className={getThemeClassName()}
            lang="en"
            style={
                {
                    "--g-font-family-primary": efCircular.style.fontFamily,
                    background: "var(--s-color-surface-secondary)",
                    color: "var(--s-color-text-primary)",
                } as CSSProperties
            }
        >
            <body>
                <ErrorPage />
            </body>
        </html>
    );
}
